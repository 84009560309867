<template>

    <div v-if="type==='participants-break-down'" v-for="section in this.$store.state.sections" >
        <ParticipantsBreakdown v-if="section.type==='participants_break_down'" :section="section" @duplicatePage="duplicatePage" @deletePage="deletePage" />
    </div>

    <div v-else-if="type==='nps'" v-for="section in this.$store.state.sections" >
        <NPSBreakdown v-if="section.type==='nps'" :section="section" @duplicatePage="duplicatePage" @deletePage="deletePage" />
        <div v-if="section.type==='nps'" class="mt-5 mb-5">
            <hr class="border border-primary border-2">
        </div>
    </div>

    <div v-else-if="type==='srf'" v-for="section in this.$store.state.sections" >
        <RetentionFactors v-if="section.type==='retention_factors'" :section="section" :type="'srf'" @duplicatePage="duplicatePage" @deletePage="deletePage" />
        <div v-if="section.type==='retention_factors'" class="mt-5 mb-5">
            <hr class="border border-primary border-2">
        </div>
    </div>

    <div v-else-if="type==='saf'" v-for="section in this.$store.state.sections" >
        <RetentionFactors v-if="section.type==='retention_factors'" :section="section" :type="'saf'" @duplicatePage="duplicatePage" @deletePage="deletePage" />
        <div v-if="section.type==='retention_factors'" class="mt-5 mb-5">
            <hr class="border border-primary border-2">
        </div>
    </div>

    <div v-else-if="type==='tvp'" v-for="section in this.$store.state.sections" >
        <TVPVariance v-if="section.type==='tvp'" :section="section" @duplicatePage="duplicatePage" @deletePage="deletePage" />
        <div v-if="section.type==='tvp'" class="mt-5 mb-5">
            <hr class="border border-primary border-2">
        </div>
    </div>

    <div v-else-if="type==='highlights'" v-for="section in this.$store.state.sections" >
        <TVPHighlights v-if="section.type==='highlights'" :section="section" @duplicatePage="duplicatePage" @deletePage="deletePage" />
        <div v-if="section.type==='highlights'" class="mt-5 mb-5">
            <hr class="border border-primary border-2">
        </div>
    </div>

    <div v-else-if="type==='lowlights'" v-for="section in this.$store.state.sections" >
        <TVPLowlights v-if="section.type==='lowlights'" :section="section" @duplicatePage="duplicatePage" @deletePage="deletePage" />
        <div v-if="section.type==='lowlights'" class="mt-5 mb-5">
            <hr class="border border-primary border-2">
        </div>
    </div>

    <div v-else-if="type==='low-variance'" v-for="section in this.$store.state.sections" >
        <TVPLowVariance v-if="section.type==='low_variance'" :section="section" @duplicatePage="duplicatePage" @deletePage="deletePage" />
        <div v-if="section.type==='low_variance'" class="mt-5 mb-5">
            <hr class="border border-primary border-2">
        </div>
    </div>

    <div v-else-if="type==='high-variance'" v-for="section in this.$store.state.sections" >
        <TVPHighVariance v-if="section.type==='high_variance'" :section="section" @duplicatePage="duplicatePage" @deletePage="deletePage" />
        <div v-if="section.type==='high_variance'" class="mt-5 mb-5">
            <hr class="border border-primary border-2">
        </div>
    </div>

    <div v-else-if="type==='pillars-of-equity'" v-for="section in this.$store.state.sections" >
        <PillarsEquity v-if="section.type==='pillars_of_equity'" :section="section" @duplicatePage="duplicatePage" @deletePage="deletePage" />
        <div v-if="section.type==='pillars_of_equity'" class="mt-5 mb-5">
            <hr class="border border-primary border-2">
        </div>
    </div>

    <div v-else-if="type==='equity-index'" v-for="section in this.$store.state.sections" >
        <EquityIndex v-if="section.type==='equity_index'" :section="section" @duplicatePage="duplicatePage" @deletePage="deletePage" />
        <div v-if="section.type==='equity_index'" class="mt-5 mb-5">
            <hr class="border border-primary border-2">
        </div>
    </div>

</template>

<script>
    import ParticipantsBreakdown from './ParticipantsBreakdown.vue';
    import NPSBreakdown from './NPSBreakdown.vue';
    import RetentionFactors from './RetentionFactors.vue';
    import TVPVariance from './TVPVariance.vue';
    import TVPHighlights from './TVPHighlights.vue';
    import TVPLowlights from './TVPLowlights.vue';
    import TVPLowVariance from './TVPLowVariance'
    import TVPHighVariance from './TVPHighVariance.vue';
    import PillarsEquity from './PillarsEquity.vue';
    import EquityIndex from './EquityIndex.vue';
    import axios from 'axios';

    export default {
        props: ['type'],
        name: 'SectionContainer',
        components: {
    ParticipantsBreakdown,
    NPSBreakdown,
    RetentionFactors,
    TVPVariance,
    TVPHighlights,
    TVPLowlights,
    TVPHighlights,
    TVPLowlights,
    TVPLowVariance,
    TVPHighVariance,
    PillarsEquity,
    EquityIndex,
    EquityIndex
},

        methods: {
            maxDuplicate(section){
                let count = 0
                for (let current_section of this.$store.state.sections){
                    if (current_section.type === section.type){
                        count += 1
                    }
                }
                return count > 5
            },
            async duplicatePage(index){
                let duplicated_section = {...this.$store.state.sections[index]}
                console.log(duplicated_section)
                duplicated_section.duplicate = 1

                if (this.maxDuplicate(this.$store.state.sections[index])){
                    this.$toast.error("Maximum Duplication of a Section Reached")
                    return
                }

                this.$toast.info("Duplicating page")

                if (duplicated_section.type === 'participants_break_down' || duplicated_section.type === 'nps'){
                    duplicated_section.filters =  {
                        section_filters: {},
                        display_filters: {
                            display_charts: []
                        }
                    }
                }

                else if (duplicated_section.type === 'retention_factors'){
                    duplicated_section.filters =  {
                        section_filters: {},
                        display_filters: {
                            display_count_saf: 17,
                            display_count_srf: 17
                        }
                    }
                }

                else if (duplicated_section.type === 'tvp'){
                    duplicated_section.filters =  {
                        section_filters: {},
                    }
                }

                else if (duplicated_section.type === 'highlights' || duplicated_section.type === 'lowlights'){
                    duplicated_section.filters =  {
                        section_filters: {},
                        display_filters: {
                            display_count: 15
                        }
                    }
                }

                else if (duplicated_section.type === 'high_variance' || duplicated_section.type === 'low_variance'){
                    duplicated_section.filters =  {
                        section_filters: {},
                        display_filters: {}
                    }
                }
                
                var url = "api/render-report-data/" + this.$store.state.selectedSurveyID + "/";
                const config = {
                params: this.$store.getters.paramsForDuplicateSection(duplicated_section, index+1)
                };

                await axios
                .get(url, config)
                .then((response) => {
                    if (response.status === 200){
                    this.$store.commit('setReportData', response.data)
                    console.log(this.$store.state.reportData)
                      this.$toast.success(`Page added Successfully`);
                    }
                    else{
                      this.$toast.warning(`No matching responses found for applied filters`);
                    }

                })
            },

            async deletePage(index){
                this.$store.state.sections.splice(index, 1)
                var url = "api/render-report-data/" + this.$store.state.selectedSurveyID + "/";
                const config = {
                params: this.$store.getters.params
                };

                this.$toast.info("Deleting page")

                await axios
                .get(url, config)
                .then((response) => {
                    if (response.status === 200){
                    this.$store.commit('setReportData', response.data)
                    console.log(this.$store.state.reportData)
                      this.$toast.success(`Page removed Successfully`);
                    }

                    else{
                      this.$toast.warning(`No matching responses found for applied filters`);
                    }
                })
            }
        }
    }
</script>