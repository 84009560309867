import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Multiselect from "vue-multiselect";
import Toaster from "@meforma/vue-toaster";


if (Object.keys(store.state.userPermissions).length === 0) {
    const token = localStorage.getItem('token')
    if (token){
        const parsedToken = JSON.parse(token)
        store.state.userPermissions = parsedToken.userPermissions
        store.state.username = parsedToken.username
        console.log(store.state.userPermissions)
    }
}

const token = localStorage.getItem('adminToken')
if (token){
    store.state.isSwitched = true
}


const setFavicon = (domain) => {
    const linkTag = document.getElementById('favicon')
    console.log("updating favicon for domain " + domain)
    linkTag.href = `assets/img/favicon/${domain}/favicon-32x32.png`
    // linkTag.setAttribute('href', `assets/img/favicon/${domain}/favicon-32x32.svg`);
}

const refreshToken = async (err) => {
    const token = localStorage.getItem('token');
    const refreshToken = JSON.parse(token).refresh
    if (!refreshToken) {
        router.push('/login')
        Promise.reject(err);
    }

    try {
      const response = await axios.post('api/token/refresh/', {
        refresh: refreshToken,
        subdomain: localStorage.getItem('subdomain')
      });
      const newToken = JSON.stringify(response.data);
      localStorage.setItem('token', newToken);
    } catch (error) {
        router.push('/login')
        Promise.reject(error);
    }
  };

const setSubdomain = (subdomain) => {
    if(subdomain === null){
        console.log("setting subdomain to edgility as default")
        subdomain = 'edgility'
    }
    localStorage.setItem("subdomain", subdomain)
}

var host = window.location.host;
if(host === 'localhost:8080'){
    console.log("running in localhost ")
    axios.defaults.baseURL = 'http://localhost:8000/'
    const urlParams = new URLSearchParams(window.location.search);
    const subdomain = urlParams.get('subdomain');
    setSubdomain(subdomain)
}else{
    console.log("setting the subdomain for host"+ host)

    var subdomain = host.split(".")[0];
    var domain = host.split(".")[1];
    let supportedDomain = ['assessimprove', 'dev-assessimprove']

    console.log("subdomain is "+ subdomain)

    if (supportedDomain.includes(domain)) axios.defaults.baseURL = `https://${subdomain}.${domain}.com/`;
    else  axios.defaults.baseURL = 'https://'+ subdomain +'.assessimprove.com/';
    
    setSubdomain(subdomain)
}

setFavicon(localStorage.getItem('subdomain'))

createApp(App).component('multiselect', Multiselect)
    .use(store)
    .use(router, axios)
    .use(Toaster)
    .mount("#app");

axios.interceptors.response.use(function (response) {
    console.log(response.config.url)
    if (response.config.url === 'api/token/'){
        store.state.userPermissions = response.data.userPermissions
        store.state.username = response.data.username
        store.state.isSwitched = false
    }
    if (response.config.url.includes('api/switch-user/')){
        store.state.userPermissions = response.data.userPermissions
        store.state.username = response.data.username
    }
    return response
    }, async function (error) {
        const originalRequest = error.config;
        if (error.config.url.includes('api/send-verification-link/') || 
                error.config.url.includes('api/verify-link') || 
                error.config.url.include('api/change-password-from-verification-link')){
            return Promise.reject(error)
        }
        if (error.config.url === 'api/token/refresh'){
            return Promise.reject(error)
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            return refreshToken(error).then((resp) => {
                return axios(originalRequest);
            });
        }
        return Promise.reject(error);
    })


axios.interceptors.request.use((config) => {
    if (config.url.includes('api/send-verification-link/') || 
    config.url.includes('api/verify-link/') || 
    config.url.includes('api/change-password-from-verification-link/')){
        console.log(config)
        return config
    }

    var token = localStorage.getItem("token");
    var token_map = JSON.parse(token);

    if (token_map){
        config.headers.Authorization = `Bearer ${token_map.access}`
    }
    return config
}, (error) => {
    return Promise.reject(error)
})